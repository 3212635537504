import { APP_INITIALIZER, NgModule, ErrorHandler } from '@angular/core'; 

import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';

import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { environment as env } from '../environments/environment';  
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component'; 

//* ------- SENTRY lib ----------------------------------
import * as Sentry from "@sentry/angular-ivy";
import { Router } from "@angular/router";
//? -----------------------------------------------------



//* ------- LOCALE --------------------------------------  
import { LOCALE_ID } from '@angular/core';
import { registerLocaleData } from '@angular/common'; 
import localeIt from '@angular/common/locales/it';
import localeEn from '@angular/common/locales/en';
import localeEs from '@angular/common/locales/es';
import localeFr from '@angular/common/locales/fr';
import localeDe from '@angular/common/locales/de'; 

const locales = {
  it: localeIt,
  en: localeEn,
  es: localeEs,
  fr: localeFr,
  de: localeDe
};

export const BROWSER_LANG = navigator.language || 'it-IT'; 
export const BROWSER_LANG_SHORT = navigator.language.split('-')[0] || 'it';   

function registerLocale() { 
  const locale = locales[BROWSER_LANG_SHORT as keyof typeof locales];
  if (locale) {
    registerLocaleData(locale, BROWSER_LANG_SHORT);
  }
}
registerLocale();   

export const BROWSER_TIMEZONE = Intl.DateTimeFormat().resolvedOptions().timeZone; 
sessionStorage.setItem('country', BROWSER_TIMEZONE); //  ( for header parameter )

 


//* ------- NGRX lib --------------------------------------
import { StoreModule, ActionReducerMap } from '@ngrx/store';
// import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { EffectsModule } from '@ngrx/effects';

import { AppState } from './interfaces/app-state';
import { appReducer } from './store/app.reducer';
import { AppEffects } from './store/app.effects';

//* ------- Auth0 lib --------------------------------------
import { AuthModule, AuthClientConfig,  AuthHttpInterceptor   } from '@auth0/auth0-angular';  

//* ------- Interceptors --------------------------------------
import { SecureInterceptor } from './interceptors/secure.interceptor';

//* ------- Google MAP lib --------------------------------------
import { GoogleMapsModule } from '@angular/google-maps';  

//* ------- Google CHARTS lib -------------------------------------
import { GoogleChartsModule  } from 'angular-google-charts';

//? **** Firebase ************************************************************
// import { getAnalytics } from 'firebase/analytics';
import { provideFirebaseApp, getApp, initializeApp } from '@angular/fire/app';
// import { provideFirestore, getFirestore } from '@angular/fire/firestore';
import { provideDatabase, getDatabase } from '@angular/fire/database';
// import { provideMessaging, getMessaging } from '@angular/fire/messaging'; 
//? ***************************************************************************
 

//* ------- components --------------------------------------
import { SideBarMenuComponent } from './features/components/side-bar-menu/side-bar-menu.component';
import { Page404Component } from './features/page404/page404.component';  
import { ToastComponent } from './features/components/toast/toast.component';
import { SpinnerComponent } from './features/components/spinner.component';
import { ComboCustomComponent } from './features/components/combo.component'; 

//* ------- pipes --------------------------------------
import { StringCapitalizePipe } from './pipes/string-capitalize.pipe'; 

//* ------- Services -------------------------------------- 
import { CookieService } from 'ngx-cookie-service'; 

//* ------- modules --------------------------------------
import { SharedModule } from './shared/shared.module'; 


 

//* ---- module store reducers --------------------------------------- 
export const reducers: ActionReducerMap<AppState> = {
  appstate: appReducer
};
//* ------------------------------------------------------------------

@NgModule({
  declarations: [
    AppComponent,   
    Page404Component,
    ToastComponent,
    StringCapitalizePipe  
  ],
  imports: [
    SharedModule,
    FormsModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    
    SideBarMenuComponent,
    
    //? * firebase enable modules **************************
    provideFirebaseApp(() => initializeApp(env.firebase)),
    provideDatabase(() => getDatabase()),
    // provideFirestore(() => getFirestore()),    
    // provideMessaging(() => getMessaging()), 
    //? *****************************************************
    
    SpinnerComponent, // deve essere importato tra i moduli perché è standalone
    ComboCustomComponent, // deve essere importato tra i moduli perché è standalone    
 
    //* google --------
    GoogleChartsModule,
    GoogleMapsModule,

    //* STORE GLOBALE ------- NGRX --------------------------------------
    StoreModule.forRoot( reducers ),
    EffectsModule.forRoot([AppEffects]),
    StoreDevtoolsModule.instrument({  // debug tool for Store 
      maxAge: 50, // Retains last 50 actions
      logOnly: env.production // limit tools only to dev mode
    }),
    // StoreRouterConnectingModule.forRoot(),   // debuger tool for router 
    //* ------------------------------------------------------------------ 

    AuthModule.forRoot( {
      domain: env.oAauthConfig.domain, 
      clientId: env.oAauthConfig.client_id, 
      authorizationParams: {
        redirect_uri: env.oAauthConfig.redirect_uri,  
        audience: env.oAauthConfig.audience,       
        useRefreshTokens: env.oAauthConfig.useRefreshTokens, 
        cacheLocation: env.oAauthConfig.cacheLocation  
      },
      httpInterceptor: {
        allowedList: env.oAauthConfig.httpInterceptor.allowedList  
      } 
    }) 

  ], 
  providers: [
    CookieService, 
    { provide: LOCALE_ID, useValue: BROWSER_LANG_SHORT  },
    { provide: 'TIMEZONE', useValue: BROWSER_TIMEZONE  },
    { provide: HTTP_INTERCEPTORS, useClass: AuthHttpInterceptor, multi: true  },    
    { provide: HTTP_INTERCEPTORS, useClass: SecureInterceptor, multi: true },

    //--- SENTRY SETUP -------------
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false, // Set to true to pop up a dialog when an error happens
      }),
    }, {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {
        //console.log('%c [INFO] :: %c *** Initialized SENTRY ***', 'color: blue', 'color: cyan' ); 
      },
      deps: [Sentry.TraceService],
      multi: true,
    }
    //-------------------------------
  ], 

 
 
  bootstrap: [AppComponent] 
})
export class AppModule { }

 

